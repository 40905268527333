export default [
   {
      title: 'Dashboard',
      route: 'dashboard',
      icon: 'PieChartIcon',
      action: 'manage',
      resource: 'dashboard',
   },
   {
      header: 'App & System',
      action: 'manage',
      resource: 'separator',
   },
   {
      title: 'OSS',
      icon: 'PackageIcon',
      action: 'manage',
      resource: 'oss',
      children: [
         {
            title: 'NIB',
            route: 'oss-nib',
            action: 'manage',
            resource: 'oss',
         },
         {
            title: 'Bidang Usaha',
            route: 'oss-bidang-usaha',
            action: 'manage',
            resource: 'oss',
         },
         {
            title: 'Perizinan RBA',
            route: 'oss-perizinan-rba',
            action: 'manage',
            resource: 'oss',
         },
         {
            title: 'KBLI',
            route: 'oss-kbli',
            action: 'manage',
            resource: 'oss',
         },
         {
            title: 'Laporan',
            route: 'oss-laporan',
            action: 'manage',
            resource: 'oss',
         },
      ],
   },
   {
      title: 'SIMBG',
      icon: 'HomeIcon',
      action: 'manage',
      resource: 'simbg',
      children: [
         {
            title: 'PBG',
            route: 'simbg-pbg',
            action: 'manage',
            resource: 'simbg',
         },
         {
            title: 'SLF',
            route: 'simbg-slf',
            action: 'manage',
            resource: 'simbg',
         },
         {
            title: 'IMB V1',
            route: 'simbg-imb',
            action: 'manage',
            resource: 'simbg',
         },
      ],
   },
   {
      title: 'SICANTIK',
      icon: 'FramerIcon',
      action: 'manage',
      resource: 'sicantik',
      children: [
         {
            title: 'Permohonan',
            route: 'sicantik-permohonan',
            action: 'manage',
            resource: 'sicantik',
         },
         {
            title: 'Izin',
            action: 'manage',
            resource: 'sicantik',
            children: [
               {
                  title: 'SIP Dokter',
                  route: 'sicantik-sipd',
                  action: 'manage',
                  resource: 'sicantik',
               },
               {
                  title: 'SIP Perawat',
                  route: 'sicantik-sipp',
                  action: 'manage',
                  resource: 'sicantik',
               },
               {
                  title: 'SIP Bidan',
                  route: 'sicantik-sipb',
                  action: 'manage',
                  resource: 'sicantik',
               },
               {
                  title: 'SIPTGz',
                  route: 'sicantik-siptgz',
                  action: 'manage',
                  resource: 'sicantik',
               },
               {
                  title: 'SIP Fisioterapi',
                  route: 'sicantik-sipf',
                  action: 'manage',
                  resource: 'sicantik',
               },
               {
                  title: 'SIP ATLM',
                  route: 'sicantik-sipatlm',
                  action: 'manage',
                  resource: 'sicantik',
               },
               {
                  title: 'SIPA',
                  route: 'sicantik-sipa',
                  action: 'manage',
                  resource: 'sicantik',
               },
               {
                  title: 'SIK Perekam Medis',
                  route: 'sicantik-sikpm',
                  action: 'manage',
                  resource: 'sicantik',
               },
               {
                  title: 'SIKTTK',
                  route: 'sicantik-sikttk',
                  action: 'manage',
                  resource: 'sicantik',
               },
            ],
         },
      ],
   },
   {
      title: 'LKPM',
      icon: 'DollarSignIcon',
      route: 'lkpm',
      action: 'manage',
      resource: 'lkpm',
   },
   {
      header: 'Other',
      action: 'manage',
      resource: 'separator',
   },
   {
      title: 'Pengaduan',
      route: 'pengaduan',
      icon: 'MessageCircleIcon',
      action: 'manage',
      resource: 'pengaduan',
   },
   {
      title: 'SKM',
      icon: 'SmileIcon',
      action: 'manage',
      resource: 'skm',
      children: [
         {
            title: 'MPP',
            route: 'skm-hasil-mpp',
            action: 'manage',
            resource: 'skm',
         },
         {
            title: 'DPMPTSP',
            route: 'skm-hasil',
            action: 'manage',
            resource: 'skm',
         },
      ],
   },
   {
      title: 'Izin Manual',
      icon: 'LayersIcon',
      action: 'manage',
      resource: 'izin-manual',
      children: [
         {
            title: 'SIUP',
            route: 'im-siup',
            action: 'manage',
            resource: 'izin-manual',
         },
         {
            title: 'SITU',
            route: 'im-situ',
            action: 'manage',
            resource: 'izin-manual',
         },
         {
            title: 'TDP',
            route: 'im-tdp',
            action: 'manage',
            resource: 'izin-manual',
         },
         {
            title: 'IMB',
            route: 'im-imb',
            action: 'manage',
            resource: 'izin-manual',
         },
         {
            title: 'IUTM',
            route: 'im-iutm',
            action: 'manage',
            resource: 'izin-manual',
         },
         {
            title: 'HO',
            route: 'im-ho',
            action: 'manage',
            resource: 'izin-manual',
         },
         {
            title: 'TDI',
            route: 'im-tdi',
            action: 'manage',
            resource: 'izin-manual',
         },
         {
            title: 'IP',
            route: 'im-ip',
            action: 'manage',
            resource: 'izin-manual',
         },
         {
            title: 'IUJK',
            route: 'im-iujk',
            action: 'manage',
            resource: 'izin-manual',
         },
      ],
   },
   {
      header: 'General',
      action: 'manage',
      resource: 'separator',
   },
   {
      title: 'Tools',
      icon: 'ToolIcon',
      action: 'manage',
      resource: 'tools',
      children: [
         {
            title: 'OSS',
            action: 'manage',
            resource: 'tools',
            children: [
               {
                  title: 'Source',
                  route: 'oss-scrap',
                  action: 'manage',
                  resource: 'tools',
               },
               {
                  title: 'Run Nib',
                  route: 'oss-scrap-run',
                  action: 'manage',
                  resource: 'tools',
               },
               {
                  title: 'Run Izin RBA',
                  route: 'oss-scrap-izin',
                  action: 'manage',
                  resource: 'tools',
               },
               {
                  title: 'Download NIB',
                  route: 'oss-scrap-download-nib',
                  action: 'manage',
                  resource: 'tools',
               },
               {
                  title: 'Repair Izin RBA',
                  route: 'oss-scrap-repair-izin-rba',
                  action: 'manage',
                  resource: 'tools',
               },
               {
                  title: 'Repair Izin Lama',
                  route: 'oss-scrap-repair-izin-lama',
                  action: 'manage',
                  resource: 'tools',
               },
               {
                  title: 'Download Izin Lama',
                  route: 'oss-scrap-download-izin',
                  action: 'manage',
                  resource: 'tools',
               },
               {
                  title: 'Tgl Terbit Izin Lama',
                  route: 'oss-scrap-terbit-izin-lama',
                  action: 'manage',
                  resource: 'tools',
               },
            ],
         },
         {
            title: 'SICANTIK',
            action: 'manage',
            resource: 'tools',
            children: [
               {
                  title: 'Run Permohonan',
                  route: 'sicantik-scrap',
                  action: 'manage',
                  resource: 'tools',
               },
               {
                  title: 'Run Detail',
                  route: 'sicantik-scrap-detail',
                  action: 'manage',
                  resource: 'tools',
               },
            ],
         },
         {
            title: 'SIMBG',
            action: 'manage',
            resource: 'tools',
            children: [
               {
                  title: 'Run PBG',
                  route: 'simbg-scrap-pbg',
                  action: 'manage',
                  resource: 'tools',
               },
               {
                  title: 'Run SLF',
                  route: 'simbg-scrap-slf',
                  action: 'manage',
                  resource: 'tools',
               },
            ],
         },
         {
            title: 'LKPM',
            action: 'manage',
            resource: 'tools',
            children: [
               {
                  title: 'Run',
                  route: 'lkpm-scrap-run',
                  action: 'manage',
                  resource: 'tools',
               },
            ],
         },
      ],
   },
   {
      title: 'Pengaturan',
      route: 'pengaturan',
      icon: 'SlidersIcon',
      action: 'manage',
      resource: 'pengaturan',
   },
   {
      title: 'Pengguna',
      route: 'pengguna',
      icon: 'UsersIcon',
      action: 'manage',
      resource: 'pengguna',
      children: [
         {
            title: 'Data',
            route: 'pengguna',
            action: 'manage',
            resource: 'pengguna',
         },
         {
            title: 'Role',
            route: 'pengguna-role',
            action: 'manage',
            resource: 'pengguna',
         },
         {
            title: 'Aktivitas',
            route: 'pengguna-aktivitas',
            action: 'manage',
            resource: 'pengguna',
         },
      ],
   },
   {
      title: 'Logs',
      route: 'logs',
      icon: 'FileTextIcon',
      action: 'manage',
      resource: 'log',
   },
]
